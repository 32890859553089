import * as Sentry from "@sentry/react";
import mixpanel from "mixpanel-browser";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

const AnalyticsContext = createContext();

export const AnalyticsProvider = ({ children }) => {
  const [isMixpanelInitialized, setIsMixpanelInitialized] = useState(false);

  useEffect(() => {
    try {
      mixpanel.init("20009d15fca6a8d06b23bcb3e6d94912", {
        debug: true, // Set to false in production
        ignore_dnt: true,
      });
      setIsMixpanelInitialized(true);
    } catch (error) {
      console.error("Failed to initialize Mixpanel:", error);
      Sentry.captureException(error);
    }
  }, []);

  const sessionStartTime = useRef(Date.now());
  const lastInteractionTime = useRef(Date.now());
  const interactionCount = useRef(0);

  const trackEvent = useCallback(
    (eventName, properties = {}) => {
      if (!isMixpanelInitialized) {
        console.warn("Mixpanel not initialized. Skipping event tracking.");
        return;
      }

      const now = Date.now();
      const enhancedProperties = {
        ...properties,
        timeSinceLastInteraction: now - lastInteractionTime.current,
        totalInteractionCount: ++interactionCount.current,
        timeSinceSessionStart: now - sessionStartTime.current,
      };

      try {
        mixpanel.track(eventName, enhancedProperties);

        if (["login", "signup", "purchase", "error"].includes(eventName)) {
          Sentry.captureMessage(eventName, {
            level: "info",
            extra: enhancedProperties,
          });
        }

        lastInteractionTime.current = now;
      } catch (error) {
        console.error("Failed to track event:", error);
        Sentry.captureException(error);
      }
    },
    [isMixpanelInitialized]
  );

  const trackPageView = useCallback(
    (path) => {
      if (isMixpanelInitialized) {
        try {
          mixpanel.track("Page View", { path });
        } catch (error) {
          console.error("Failed to track page view:", error);
          Sentry.captureException(error);
        }
      }

      Sentry.addBreadcrumb({
        category: "navigation",
        message: `Viewed ${path}`,
        level: "info",
      });
    },
    [isMixpanelInitialized]
  );

  const identifyUser = useCallback(
    (userId, traits = {}) => {
      if (isMixpanelInitialized) {
        try {
          mixpanel.identify(userId);
          mixpanel.people.set(traits);
        } catch (error) {
          console.error("Failed to identify user in Mixpanel:", error);
          Sentry.captureException(error);
        }
      }

      Sentry.setUser({ id: userId, ...traits });
    },
    [isMixpanelInitialized]
  );

  return (
    <AnalyticsContext.Provider
      value={{ trackEvent, trackPageView, identifyUser }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useAdvancedAnalytics = () => {
  const context = useContext(AnalyticsContext);
  if (!context) {
    throw new Error("useAnalytics must be used within an AnalyticsProvider");
  }
  return context;
};
